import React, {useEffect} from "react";
import Navbar from "./navbar";
import Footer from "./footer";

const Layout = ({children}) => {
	useEffect(() => {
		import('preline');
	}, [])
	return (
		<div>
			<Navbar/>
			<main id="content" role="main">
				{children}
			</main>
			<Footer />
		</div>
	)
}
export default Layout;