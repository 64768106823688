import * as React from "react"
import {useSiteMetadata} from "../../hooks/use-site-metadata";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

const Navbar = () => {
	const site = useSiteMetadata();
	return (
		<header
			className="flex flex-wrap sm:justify-start sm:flex-col z-50 w-full bg-white border-b border-gray-200 text-sm pb-2 sm:pb-0 dark:bg-gray-800 dark:border-gray-700">
			<div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between gap-x-5 w-full py-1 sm:pt-2 sm:pb-0">
					<div className="flex items-center justify-start gap-x-5">
						<a
							className="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-indigo-500 text-sm dark:text-slate-400 dark:hover:text-slate-300"
							href={`tel:${site.phone}`}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								 className="bi bi-phone" viewBox="0 0 16 16">
								<path
									d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
								<path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
							</svg>
							<span className={'hidden sm:block'}>{site.phone}</span>
						</a>
						<a
							className="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-indigo-500 text-sm dark:text-slate-400 dark:hover:text-slate-300"
							href={`mailto:${site.email}`}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								 className="bi bi-envelope-at" viewBox="0 0 16 16">
								<path
									d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
								<path
									d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
							</svg>
							<span className={'hidden sm:block'}>{site.email}</span>
						</a>
					</div>
					<div className="flex items-center justify-end gap-x-5">
						<a className="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-indigo-500 text-sm dark:text-slate-400 dark:hover:text-slate-300"
						   href="#!">
							<svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
								 fill="currentColor" viewBox="0 0 16 16">
								<path
									d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
							</svg>
							English (US)
						</a>
						<a className="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-indigo-500 text-sm dark:text-slate-400 dark:hover:text-slate-300"
						   href="https://my.ivsouq.com/become-our-partner">Become Our Partner</a>
						<a className="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-indigo-500 text-sm dark:text-slate-400 dark:hover:text-slate-300"
						   href="https://my.ivsouq.com/login">Sign In</a>
						<a
							className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-indigo-600 hover:bg-indigo-50 hover:border-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all text-sm dark:hover:bg-indigo-600/[.3] dark:border-slate-700 dark:hover:border-indigo-500 dark:hover:text-indigo-500"
							href="https://my.ivsouq.com/register"
						>
							Get started
						</a>
					</div>
				</div>
			</div>

			<nav
				className="relative max-w-7xl w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
				aria-label="Global">
				<div className="flex items-center justify-between">
					<Link
						className="flex-none text-xl font-semibold dark:text-white"
						to="/"
						aria-label={site.title}
					>
						<StaticImage
							src={'../../images/logo/iconic.png'}
							alt={site.title}
							height={30}
						/>
					</Link>
					<div className="sm:hidden">
						<button type="button"
								className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-indigo-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
								data-hs-collapse="#navbar-collapse-with-animation"
								aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
							<svg className="hs-collapse-open:hidden w-4 h-4" width="16" height="16"
								 fill="currentColor" viewBox="0 0 16 16">
								<path fill-rule="evenodd"
									  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
							</svg>
							<svg className="hs-collapse-open:block hidden w-4 h-4" width="16" height="16"
								 fill="currentColor" viewBox="0 0 16 16">
								<path
									d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
							</svg>
						</button>
					</div>
				</div>
				<div id="navbar-collapse-with-animation"
					 className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
					<div
						className="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">
						<Link
							className="font-medium text-secondary hover:text-primary sm:py-6 dark:text-gray-200 dark:hover:text-gray-400"
							to="/"
							aria-current="page"
							activeClassName={'text-primary'}
						>
							Home
						</Link>
						<Link
							className="font-medium text-secondary hover:text-primary sm:py-6 dark:text-gray-200 dark:hover:text-gray-400"
							to="/about"
							activeClassName={'text-primary'}
						>
							About
						</Link>
						<Link
							className="font-medium text-secondary hover:text-primary sm:py-6 dark:text-gray-200 dark:hover:text-gray-400"
							to="/contact"
							activeClassName={'text-primary'}
						>
							Contact
						</Link>
					</div>
				</div>
			</nav>
		</header>
	);
}

export default Navbar;
