import {graphql, useStaticQuery} from "gatsby"

export const useSiteMetadata = () => {
	const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
					keywords
                    twitterUsername
                    image
                    siteUrl
					phone
					email
					address
                }
            }
        }
	`)

	return data.site.siteMetadata
}